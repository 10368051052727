.app-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: #fcfcfc;
  margin-top: 10px;
}

.search-filters {
   display: none;
}

.search-results {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 90%;
}

.search_tutorial {
    width: 90%;
}
@media screen and (min-width: 480px) {
  .app-body {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;
    width: 95%;
    margin: 0 auto;
  }
  .search-filters {
    flex: 0.3;
  }

  .search-results {
    flex: 0.7;
  }
}
