.main-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-layout-header {
  /*padding: 32px 24px;*/
  border-bottom: 1px solid #eee;
  width: 5vw;
  padding-top: 10px;
}
.main-layout-body {
  background: #fcfcfc;
  width: 85vw;
}
.main-layout-footer {
    /*padding: 32px 24px;*/
    border-bottom: 1px solid #eee;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(157, 161, 156);
    color: white;
    text-align: center;
}
.card {
  grid-area: "card";
  justify-content: center;
  background-color: white;
  width: 900px;
  margin-top: 50px;
  height: 100%;
  border-style: solid;
  border-color: white;
}

.sidebar {
  grid-area: "sidebar";
}

.content {
  display: grid;
  grid-template-areas: "sidebar card";
  grid-template-columns: 350px 1fr;
  background-color: #fcfcfc;
  /* height: 100vh; */
}

.chart_container {
    margin: 0 auto;
    max-width: 200px;
}
.horizontal_list {
    list-style: none;
    padding-inline-start: 0px;
    margin-block-start:0em;
    margin-block-end:0em;
 }

.horizontal_list li {
display: inline-block;
}

.horizontal_list li:not(:last-child):after {
content: ", ";
}

.vertical_list {
    list-style: none;
    padding-inline-start: 0px;
    margin-block-start:0em;
    margin-block-end:0em;
 }

.vertical_list li {
    margin-left: 40px;
    list-style-type: disc;
}


.header-text-overcome {
    vertical-align: top;
    font-size: xx-large;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 10px;
}

.header-text-beta {
    vertical-align: top;
    font-size: small;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 1px;
}

.header-text-links {
    vertical-align: top;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 35px;
    margin-top: 25px;
}
.search_tutorial {
    width:80vw;
}
#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none;
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3498db;
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3f3ce7;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #a4c9ff;
    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
