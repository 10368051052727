@import url(https://fonts.googleapis.com/css?family=Overpass);
body {
  height: 100vh;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  font-family: "Overpass", sans-serif;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;

  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  &:focus-within {
    border: 1px solid #0052cc;
  }
  input {
    flex: 1 1;
    border: none;
    height: 46px;
    font-size: 14px;
    padding: 4px 0 0 0;
    width:400px;
    outline: transparent;
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 8px 0;
  background: #0052cc;
}
.tag-title {
  margin-top: 3px;
}
.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}

.no-outline {
  border: none;
  align-self: center;
  width: 100%;
}

.no-outline:focus {
  border: none;
  outline: none;
}

@media screen and (min-width: 480px) {
  .tags-input {
    min-height: 54px;
  }
}

.search-box{display:flex;flex-direction:column;align-items:center;position:relative;justify-content:left;font-family:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif}.search-box .MuiTextField-root{width:100%}.search-box-tags{width:100%;margin-bottom:7px}.search-box-last{display:flex;flex-direction:column;width:100%}.search-box-author{width:600px;margin-bottom:7px;padding-right:20px;margin-right:20px}.search-box-button button{width:100%}@media screen and (min-width: 480px){.search-box{width:85vw;flex-direction:row}.search-box .MuiTextField-root{width:600px}.search-box-last{flex-direction:row;width:100%;align-items:center}.search-box-last .search-box-author{width:85%}.search-box-last .search-box-author .MuiTextField-root{width:100%}.search-box-last .search-box-button{width:10%;margin-left:10px}.search-box-button button{margin-left:10px}.main-layout-header{width:95vw}.suggestion{background-color:#efefef;border:2px;position:absolute;z-index:1;width:210px;overflow:hidden;border-radius:10px;box-shadow:1px 1px 5px rgba(0,0,0,0.2)}.suggestion img{width:10px}.suggestion ul li{list-style:none}}

.show-filters-button button{margin-top:20px;padding:10px;font-family:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;line-height:1.5;font-weight:400;font-size:14px;font-weight:700;color:#3a56e4;background-color:#fff;border:1px solid #3a56e4;border-radius:4px}.filters-div{display:none}.filter-key{align-items:center;justify-content:space-between;cursor:pointer}.filter-div{display:flex;align-items:center;justify-content:space-between;cursor:pointer}.aggreagtions-obj-key{margin-left:30px;margin-right:30px}.aggreagtions-obj-key .MuiPaper-root{width:80%}@media screen and (min-width: 480px){.show-filters-button{display:none}.filters-div{display:inherit;color:#4f4f4f;font-size:13px;margin:8px 0}.filter-key{display:flex;align-items:center;justify-content:space-between;cursor:pointer;width:95%}.filter-row{display:flex;align-items:center;justify-content:space-between;cursor:pointer}.value{color:#888;font-size:0.85em;margin-left:24px}.sort-by-div .MuiInputBase-root{width:90%}}

.pagination-header{display:flex;width:100%;justify-content:space-between;align-items:center;font-size:12px;color:#4a4b4b}

.pagination-footer{display:flex;justify-content:space-evenly;cursor:pointer}

.see-more-btn{color:gray;text-decoration:underline;border-style:none;background:none;cursor:pointer;font-weight:bold}

.card-left-item{font-family:monospace;font-weight:400;font-size:14px;flex:0 1 50%;color:#777}.card-left-value{font-weight:400;font-size:14px}.results-main-div .MuiPaper-root{width:100%}

.app-body{display:flex;flex-direction:column;align-items:center;position:relative;width:100%;background-color:#fcfcfc;margin-top:10px}.search-filters{display:none}.search-results{display:flex;flex-direction:column;margin-top:20px;width:90%}.search_tutorial{width:90%}@media screen and (min-width: 480px){.app-body{flex-direction:row;align-items:baseline;justify-content:space-around;width:95%;margin:0 auto}.search-filters{flex:0.3 1}.search-results{flex:0.7 1}}

.main-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-layout-header {
  /*padding: 32px 24px;*/
  border-bottom: 1px solid #eee;
  width: 5vw;
  padding-top: 10px;
}
.main-layout-body {
  background: #fcfcfc;
  width: 85vw;
}
.main-layout-footer {
    /*padding: 32px 24px;*/
    border-bottom: 1px solid #eee;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(157, 161, 156);
    color: white;
    text-align: center;
}
.card {
  grid-area: "card";
  justify-content: center;
  background-color: white;
  width: 900px;
  margin-top: 50px;
  height: 100%;
  border-style: solid;
  border-color: white;
}

.sidebar {
  grid-area: "sidebar";
}

.content {
  display: grid;
  grid-template-areas: "sidebar card";
  grid-template-columns: 350px 1fr;
  background-color: #fcfcfc;
  /* height: 100vh; */
}

.chart_container {
    margin: 0 auto;
    max-width: 200px;
}
.horizontal_list {
    list-style: none;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    -webkit-margin-before:0em;
            margin-block-start:0em;
    -webkit-margin-after:0em;
            margin-block-end:0em;
 }

.horizontal_list li {
display: inline-block;
}

.horizontal_list li:not(:last-child):after {
content: ", ";
}

.vertical_list {
    list-style: none;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    -webkit-margin-before:0em;
            margin-block-start:0em;
    -webkit-margin-after:0em;
            margin-block-end:0em;
 }

.vertical_list li {
    margin-left: 40px;
    list-style-type: disc;
}


.header-text-overcome {
    vertical-align: top;
    font-size: xx-large;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 10px;
}

.header-text-beta {
    vertical-align: top;
    font-size: small;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 1px;
}

.header-text-links {
    vertical-align: top;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 35px;
    margin-top: 25px;
}
.search_tutorial {
    width:80vw;
}
#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none;
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3498db;
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #3f3ce7;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #a4c9ff;
    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}

body {
  
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

