.card-left-item {
  font-family: monospace;
  font-weight: 400;
  font-size: 14px;
  flex: 0 1 50%;
  color: #777;
}
.card-left-value {
  font-weight: 400;
  font-size: 14px;
}
.results-main-div {
  .MuiPaper-root {
    width: 100%;
  }
}
