.show-filters-button {
  button {
    margin-top: 20px;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    font-size: 14px;
    font-weight: 700;
    color: #3a56e4;
    background-color: #fff;
    border: 1px solid #3a56e4;
    border-radius: 4px;
  }
}
.filters-div {
  display: none;
}

.filter-key {
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  // width: 95%;
}
.filter-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  // width: 95%;
}
.aggreagtions-obj-key {
  margin-left: 30px;
  margin-right: 30px;
  .MuiPaper-root {
    width: 80%;
  }
}

@media screen and (min-width: 480px) {
  .show-filters-button {
    display: none;
  }
  .filters-div {
    display: inherit;
    color: #4f4f4f;
    font-size: 13px;
    margin: 8px 0;
  }

  .filter-key {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 95%;
  }
  .filter-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    // width: 95%;
  }

  .value {
    color: #888;
    font-size: 0.85em;
    margin-left: 24px;
  }
  .sort-by-div {
    .MuiInputBase-root {
      width: 90%;
    }
  }
}
