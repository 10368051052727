.search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: left;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  .MuiTextField-root {
    width: 100%;
    // height: 10px;
  }
}
.search-box-tags {
  width: 100%;
  margin-bottom: 7px;
}
.search-box-last {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.search-box-author {
  width: 600px;
  margin-bottom: 7px;
  padding-right:20px;
  margin-right: 20px;
}
.search-box-button {
  // width: 100%;
  button {
    width: 100%;
  }
}

@media screen and (min-width: 480px) {
  .search-box {
    width: 85vw;
    flex-direction: row;
    .MuiTextField-root {
      width: 600px;
    }
  }
  .search-box-last {
    flex-direction: row;
    width: 100%;
    align-items: center;
    .search-box-author {
      width: 85%;
      .MuiTextField-root {
        width: 100%;
      }
    }
    .search-box-button {
      width: 10%;
      margin-left: 10px;

    }
  }
  .search-box-button button {

    margin-left: 10px;
  }
  .main-layout-header {
    width: 95vw;
  }

  .suggestion {
    background-color: rgb(239, 239, 239);
    border: 2px;
    position: absolute;
    z-index: 1;
    width: 210px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

    img {
      width: 10px;
    }
  }

  .suggestion ul li {
    // list-style-image: url("../../images/history.png");
    list-style: none;
  }
}
